<script>
    import V from 'voUtils/V.js'



    export default {
    	voVueComponent: 'image-gallery',
    	props: {
    		galleryClass: String
    	},

    	mounted: function() {
    		$('.' + this.galleryClass).swipebox({
    			selector: '.' + this.galleryClass,
    			useSVG: false,
    			loopAtEnd: true,
    			hideBarsDelay: 5000
    		});
    	}
    };
</script>