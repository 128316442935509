import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

		/*	$('.mainHeader').affix({
				offset: {
					top: 100
				}
			});
			$('.inner-page').affix({
				offset: {
					top: 100
				}
			}); */
		new Mmenu(document.querySelector('#mobilemenu'), {
			onClick: {
				close: true
			}
		});

		document.addEventListener('click', function (evnt) {
			var anchor = evnt.target.closest('a[href^="#/"]');
			if (anchor) {
				alert("Thank you for clicking, but that's a demo link.");
				evnt.preventDefault();
			}
		});


	},

	methods: {

		navbarToggle: function () {

			var nav = this.$el;

			if (nav.className === "vo-nav-bar") {
				nav.className += " opened";
			} else {
				nav.className = "vo-nav-bar";
			}


		},
		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURIComponent(this.name);
			}
		}
	}
};